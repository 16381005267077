import { BaseStore, getOrCreateStore } from 'next-mobx-wrapper';
import { action, computed, observable } from 'mobx';

import SiteInfo from 'globals/SiteInfo';

class ThemeState extends BaseStore {
  @observable public contextTheme: any = require('styles/themes/rewardsClub/contextTheme');
  @observable private _site = 'rewardsClub';

  @action public switchTheme = (site: string) => {
    if (this._siteList.includes(site) && this.site !== site) {
      let theme: Theme = {};
      theme = require('styles/themes/rewardsClub/contextTheme');
      this._site = site;
      this.contextTheme = theme;
    } else {
      this.loadDefaultTheme();
    }
  }

  @computed get siteInfo() {
    return SiteInfo.filter(n => n.slug === this.site)[0];
  }

  @computed public get site() {
    return this._site;
  }
}

export const getThemeState = getOrCreateStore('themeStore', ThemeState);
export default ThemeState;
