// import { action, computed } from 'mobx';
import { BaseStore, getOrCreateStore } from 'next-mobx-wrapper';

class AppState extends BaseStore {
  public webhost = process.env.NODE_ENV === 'development' ? 'nginx' : 'localhost';
}

export const getAppState = getOrCreateStore('appStore', AppState);

export default AppState;
