import 'isomorphic-fetch';
import '../main.scss';

import * as React from 'react';
import * as Sentry from 'isomorphic-sentry';
import * as getStores from '../stores';

import App, { AppContext, AppInitialProps } from 'next/app';
import { Footer, Header } from 'components/layout';
import { NextComponentType, NextPageContext } from 'next';
import { Provider, useStaticRendering } from 'mobx-react';

import { ApolloClient } from 'apollo-client';
import { ApolloProvider } from '@apollo/react-hooks';
import HTMLReactParser from 'html-react-parser';
import Head from 'next/head';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import {Elements as StripeWrapper} from '@stripe/react-stripe-js';
import { ThemeProvider } from '@friendsofreactjs/react-css-themr';
import UserService from 'lib/UserService';
import { captureException } from 'isomorphic-sentry';
import {loadStripe} from '@stripe/stripe-js';
import path from 'path';
import seoQuery from './seo.graphql';
import withApollo from 'lib/withApollo';
import { withMobx } from 'next-mobx-wrapper';
import { withServerContext } from 'next-server-context';

const root = path.resolve(__dirname, '../../');
require('dotenv').config( { path: root + '.env' } );
const isBrowser = typeof window !== 'undefined';
useStaticRendering(!isBrowser); // eslint-disable-line react-hooks/rules-of-hooks
const stripePromise = loadStripe(process.env.STRIPE_PUB_KEY || '');

interface Props {
  Component: NextComponentType;
  ctx: NextPageContext  & { store: Stores } & { apolloClient: any };
  store: Stores;
  req?: any;
  apolloClient: ApolloClient<NormalizedCacheObject>;
  path: string;
  query?: string;
  seo?: SEOMaticDataWithScripts;
}

if (process.env.NODE_ENV !== 'development' && process.env.SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    ignoreErrors: [/Cannot read property 'vdata.*/, '<unknown>', 'undefined']
  });
}

class Main extends App<Props & AppInitialProps> {
  static async getInitialProps(ctx: AppContext) {
    let session;
    let seoResult;
    let pageProps;

    await Promise.all([
      await ctx?.ctx?.store?.globals.init(ctx.ctx.apolloClient!)
    ]);

    const getQueries = async (ctx: AppContextWithStores) => {
      const uri = ctx.router.asPath.replace(/\?.*/, ''); // Strip query string

      const promises = [
        App.getInitialProps(ctx),
        await UserService.loggedInSSR(ctx?.ctx?.req?.headers.cookie!),
        await ctx?.ctx?.apolloClient?.query<SEOQueryData>({
          query: seoQuery,
          variables: {
            uri
          }
        })
      ];

      if (['/accommodation', '/'].includes(ctx.router.pathname)) {
        promises.push(await ctx?.ctx?.store?.accommodationStore.init(ctx.ctx.apolloClient!, ctx.router.pathname, ctx.router.query));
      }

      if (['/gift-cards', '/gift-cards/[slug]', '/cart', '/signup'].includes(ctx.router.pathname)) {
        promises.push(await ctx?.ctx?.store?.cart.init(ctx.ctx.apolloClient!));
      }

      return promises;
    };

    try {
      [pageProps, session, seoResult] = await Promise.all(await getQueries(ctx));
    } catch (e) {
      ctx.ctx.res?.writeHead(404).end();
    }

    // check and handle SEO
    const seo = seoResult?.data.seomatic;
    try {
    // Correct URLs from site handle
      if (seo) {
        seo.metaTagContainer = seo?.metaTagContainer.replace(/"http:\/\/(\w*?)([\/"])/g, "https://www.$1.com.au$2");
        seo.metaJsonLdContainer = seo?.metaJsonLdContainer.replace(/"http:\/\/(\w*?)([\/"])/g, "https://www.$1.com.au$2");

        const scriptObject: SEOMaticMetaScriptObject = JSON.parse(seo.metaScriptContainer);
        seo.headScripts = scriptObject.script;
        seo.bodyScripts = scriptObject.bodyScript;
      }
    } catch (e) {
      if (seo) {
        seo.headScripts = seo?.metaScriptContainer; // Fallback for SEOMatic < 3.2.39
      } else {
        captureException(`seo not defined ${e}`);
      }
    }

    // check if user is loggd in
    if (ctx.ctx.req) {
      const response = await ctx?.ctx?.store?.userStore.checkLogin(session, ctx.router.pathname, ctx?.ctx?.req?.headers.cookie!);
      // reads session info and stores info in userStore.loggedIn
      if (!response) {
        ctx?.ctx?.res?.writeHead(302, { Location: '/register?signup=true'}).end();
      }
    }

    return { ...pageProps, path: ctx.router.asPath.replace(/\?.+/, ''), store: ctx.ctx.store, seo };
  }

  componentDidMount() {
    this.props.store.accommodationStore.hydrate();

    if (this.props.store.userStore.loggedIn) {
      this.props.store.cart.hydrate();
    }

    if (window?.WePay) {
      // used by fusebill
      window.WePay.risk.generate_risk_token();
    }
    loadStripe(process.env.STRIPE_PUB_KEY || '');
  }

  componentDidCatch(error: any, errorInfo: any) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });
  }

  public render() {
    const { Component, pageProps, path, store, apolloClient, seo } = this.props;
    if (pageProps.error && pageProps.error.code !== 404) {
      Sentry.captureException(pageProps.error.message);
    }

    return (
      <ApolloProvider client={apolloClient}>
        <Provider {...store}>
          <Head>
            {seo && HTMLReactParser(
              seo.metaTitleContainer + seo.metaTagContainer +
              seo.metaJsonLdContainer + seo.headScripts
            )}
          </Head>
          {seo && seo.bodyScripts && HTMLReactParser(seo.bodyScripts)}
          <ThemeProvider theme={store && store.themeState.contextTheme}>
            <StripeWrapper stripe={stripePromise}>
              <>
                <Header path={path}/>
                <Component {...pageProps}/>
                <Footer path={path}/>
              </>
            </StripeWrapper>
          </ThemeProvider>
        </Provider>
      </ApolloProvider>
    );
  }
}

export default withMobx(getStores)(withApollo(withServerContext(Main)));
