/* eslint-disable global-require */
module.exports = {
  Footer: require('./layout/Footer.scss'),
  Header: require('./layout/Header.scss'),
  MobileCTABar: require('./layout/MobileCTABar.scss'),
  TopMenu: require('./layout/TopMenu.scss'),

  ArticleCard: require('./components/ArticleCard.scss'),
  JoinPrompt: require('./components/JoinPrompt.scss'),
  LoginModal: require('./components/LoginModal.scss'),
  ProfileSummary: require('./components/ProfileSummary.scss'),
  SignUpBlock: require('./components/SignUpBlock.scss'),
  Subscription: require('./components/Subscription.scss'),
  MobileMenu: require('./components/MobileMenu.scss'),
  UserMenu: require('./components/UserMenu.scss'),

  Article: require('./pages/Article.scss'),
  News: require('./pages/News.scss'),
  Property: require('./pages/Property.scss'),
  Register: require('./pages/Register.scss'),
  SignIn: require('./pages/SignIn.scss'),
  SignUp: require('./pages/SignUp.scss')
};
