import { BaseStore, getOrCreateStore } from 'next-mobx-wrapper';

import { ApolloClient } from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { observable } from 'mobx';
import query from './query.graphql';

class Globals extends BaseStore {
  @observable siteTitle?: string;
  @observable description?: string;
  @observable logo?: string;
  @observable phone?: string;
  @observable address?: {
    address: string;
    parts: {
      postcode: string;
      city: string;
      state: string;
      country: string;
      number: string;
    };
  };
  @observable openingHours?: {
    hours: string;
    day: string;
  }[];
  @observable facebook?: string;
  @observable instagram?: string;
  @observable monthlyCost?: string;
  @observable termsAndConditons?: string;
  @observable hideJoinDate = false;

  async init(apolloClient: ApolloClient<NormalizedCacheObject>) {
    const results = await apolloClient.query({ query });
    if ((!results.errors || results.errors.length) && results?.data?.globalSets?.length) {
      this.siteTitle = results.data.globalSets[0].siteTitle;
      this.description = results.data.globalSets[0].description;
      this.logo = results.data.globalSets[0].logo && results.data.globalSets[0].logo[0].url;
      this.phone = results.data.globalSets[0].phone;
      this.address = results.data.globalSets[0].address;
      this.facebook = results.data.globalSets[0].facebook;
      this.instagram = results.data.globalSets[0].instagram;
      this.twitter = results.data.globalSets[0].twitter;
      this.youtube = results.data.globalSets[0].youtube;
      this.monthlyCost = results.data.globalSets[0].monthlyCost;
      this.openingHours = results.data.globalSets[0].openingHours;
      this.termsAndConditions = results.data.globalSets[0].termsAndConditions;
      this.hideJoinDate = results.data.globalSets[0].hideJoinDate;
    }
  }
}

export const getGlobals = getOrCreateStore('globals', Globals);
export default Globals;
