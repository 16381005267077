import { addYears, eachDayOfInterval, format, subDays } from 'date-fns';

import { Truncate } from 'lib/index';
import { xor } from 'lodash';

// eslint-disable-next-line @typescript-eslint/no-empty-interface, semi
export default interface Property extends Accommodation.PropertyResult {}

export default class Property implements Accommodation.PropertyResult {
  constructor(property: Accommodation.PropertyResult, cms = false) {
    if (property) {
      Object.assign(this, property);
    }

    if (cms && property) {
      this.cms = cms;
      if (property.location) {
        this.lat = property.location.lat;
        this.lng = property.location.lng;
      }
    }

    this.unavailability = this.getUnavailableDates();
  }

  cms = false;
  available = true;
  unavailability: Date[] = [];

  // returns boolean of range
  checkAvailability = (from: string, to: string) => {
    if (!this.cms) {
      const requiredDays = eachDayOfInterval({ start: new Date(from), end: subDays(new Date(to), 1) });
      this.available = requiredDays.every((d: Date) =>
        this.availability.includes(format(d, 'yyyy-MM-dd'))
      );
      return this.available;
    }
    return true;
  }

  // gets array of availability range dates
  getUnavailableDates = () => {
    if (!this.cms) {
      const twoYears = eachDayOfInterval({ start: new Date(), end: addYears(new Date(), 2) })
        .map((d: Date) => format(d, 'yyyy-MM-dd'));
      return xor(this.availability, twoYears).map((d: string) => new Date(d));
    }
  }

  meetsFilterConditions = (filters: Accommodation.FilterTypes) => {
    const activeFilters = Object.keys(filters).filter((f: string)=> filters[f]);
    let valid = true;

    if (activeFilters?.length) {
      const status: boolean[] = [];
      activeFilters.forEach((f: string)=> {
        // TODO: ignore type errors as they will always be defined
        switch(f) {
          case 'guests':
            status.push(this.maxAdults >= filters.guests!);
            break;
          case 'beds':
            status.push(this.beds >= filters.beds!);
            break;
          case 'baths':
            status.push(this.bathrooms >= filters.baths!);
            break;
          case 'minRate':
            status.push(this.baseRate >= filters.minRate!);
            break;
          case 'maxRate':
            status.push(this.baseRate <= filters.maxRate!);
            break;
          case 'location':
            status.push(this.listLocation === filters.location);
          default:
            break;
        }
        // if any of the filters are false property is not valid
        valid = !status.filter(s => !s)?.length;
      });
    }
    // if no filters return true
    return valid;
  }

  get coords() {
    return {
      lat: this.lat ? parseFloat(this.lat) : 0,
      lng: this.lng ? parseFloat(this.lng): 0
    };
  }

  get baseRate() {
    // Little Hotellier
    if (this.rates) {
      return this.priceOverride || this.rates.rate;
    }
    // CMS
    if (this.rate) {
      return this.rate;
    }
    return this.defaultRate;
  }

  get discount() {
    return (Math.floor(100 - (this.baseRate/this.defaultRate)*100));
  }

  get shortTitle() {
    return this.title && Truncate(this.title, 80);
  }

  get shortDescription() {
    return this.description && Truncate(this.description, 70);
  }

  get mainImage() {
    if (this.photos?.length) {
      return this.photos[0].standard_url;
    }
    return '';
  }

  get listLocation() {
    if (this.cms && this.location) {
      return `${this.location.parts.city}, ${this.location.parts.state}`;
    }
    return `${this.suburb}, ${this.state}`;
  }

  get city() {
    return this.suburb || this.location?.parts?.city;
  }

  get fullAddress() {
    if (this.cms && this.location) {
      return this.location.fullAddress;
    }
    return `${this.addressLine1}${this.addressLine2 ? ` ${this.addressLine2},` : ', '} ${this.suburb}, ${this.state}`;
  }
}
