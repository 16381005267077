import { ApolloClient } from 'apollo-client';
import { InMemoryCache, NormalizedCacheObject, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import { disableFragmentWarnings } from 'graphql-tag';

disableFragmentWarnings();

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null;

const isBrowser = typeof window !== 'undefined';
interface Options {
  getToken?: () => string;
}

function create(initialState: any, {}: Options) {
  const server = process.env.NGINX_HOST;
  const url = process.browser ? '' : `http://${server}`;
  let httpLink;
  if (initialState.token) {
    httpLink = createHttpLink({ uri: `${url}/graphql?token=${initialState.token}` });
  } else {
    httpLink = createHttpLink({ uri: `${url}/graphql` });
  }

  const authLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      'Authorization': process.browser ? undefined : `Bearer ${process.env.GRAPHQL_TOKEN!.replace(/"/g, '')}`
    }
  }));

  // https://github.com/apollographql/apollo-client/issues/3397
  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: {
      __schema: { types: [] },
    },
  });

  return new ApolloClient({
    connectToDevTools: isBrowser,
    ssrMode: !isBrowser, // Disables forceFetch on the server (so queries are only run once)
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({ fragmentMatcher }).restore(initialState || {})
  });
}

export default function initApollo(initialState: any, options: Options) {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (!isBrowser) {
    return create(initialState, options);
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = create(initialState, options);
  }

  return apolloClient;
}
