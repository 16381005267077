import { BaseStore, getOrCreateStore } from 'next-mobx-wrapper';
import { FuseBillService, UserService } from 'lib/index';
import { action, computed, flow, observable } from 'mobx';

import { captureException } from 'isomorphic-sentry';

class UserStore extends BaseStore {
  @observable loaded = false;
  @observable loading = false;
  @observable error  = false;
  @observable signUpModal = false;
  @observable loginModal  = false;
  @observable forgotPasswordModal = false;
  @observable loggedIn?: boolean;
  @observable incompleteSignup?: boolean = false;
  @observable _userProfile: UserProfileData = {
    firstName: '',
    lastName: '',
    dob: {
      date: undefined
    },
    email: '',
    userName: '',
    employer: '',
    governmentId: '',
    address: '',
    suburb: '',
    postcode: undefined,
    phone: '',
    maskedCC: '',
    cardExpirationMonth: undefined,
    cardExpirationYear: undefined,
    cardType: '',
    since: {
      date: undefined
    },
    oldMemberJoin: null,
    activePayment: false
  }

  constructor(initialState: UserStore) {
    super();
    if (initialState) {
      this.incompleteSignup = initialState.incompleteSignup;
      this.loggedIn = initialState.loggedIn;
      this._userProfile = initialState._userProfile;
    }
  }

  @observable _billingStatus: FuseBill.Response.UserStatus = {
    status: '',
    accountStanding: '',
    nextBillingDate: null
  }

  @observable profileOption?: "Edit Profile" | "Subscription" | "Track Order" | "Payment" = undefined;

  @action setProfileOption = (option: "Edit Profile" | "Subscription" | "Track Order" | "Payment" | undefined) => {
    this.profileOption = option;
  }

  @action checkLogin = async (session: UserSession, pathname: string, cookie: string) => {
    this.loggedIn = session ? !session.isGuest : false;
    if (this.loggedIn) {
      const userQueries = async () => {
        const userPromises = [
          await UserService.getUserDataSSR(cookie)
        ];
        // check if user is logged in, but hasn't completed registration /
        if (['/register', '/profile', '/signin', '/', '/cart'].includes(pathname)) {
          userPromises.push(await UserService.incompleteSSR(cookie));
        }
        return userPromises;
      };

      const [userData, incomplete] = await Promise.all(await userQueries());

      this.loadSignedInUser(userData);

      // check if user is logged in, but hasn't completed registration /
      if (['/register', '/profile', '/signin', '/'].includes(pathname)) {
        this.setPartialRegistration(incomplete);
        if (pathname !== '/register' && incomplete) {
          return false;
        }
      }
      return true;
    }
    return true;
  }

  @action confirmActiveUser = async () => {
    if (!this.loggedIn) {
      return {
        success: false,
        message: 'You must be signed in to order this item'
      };
    } else if (!this.userProfile.activePayment || this.userProfile.accountStanding !== 'Good') {
      // if for some reason user doesn't have an active payment or account standing is not good,
      // check with fusebill to confirm
      const response = await this.getBillingStatus();

      if (response?.status !== 'Active' || response?.accountStanding !== 'Good') {
        return {
          success: false,
          message: 'There is an issue with your payment method'
        };
      }
    }
    return {
      success: true,
      message: ''
    };
  }

  @action toggleSignUp = () => {
    if (this.loginModal) {
      this.loginModal = false;
    }

    if (this.forgotPasswordModal) {
      this.forgotPasswordModal = false;
    }

    this.signUpModal = !this.signUpModal;
  }

  @action setLoaded = () => {
    this.loaded = true;
  }

  getOrders = flow<CommerceOrder[], any>(function *(
    this: UserStore
  ) {
    const orders = yield UserService.getOrders();
    return orders?.filter((o: CommerceOrder) => o.isPaid && o.shortNumber && o.lineItems);
  }
  )

  @action toggleLogin = () => {
    if (this.signUpModal) {
      this.signUpModal = false;
    }

    if (this.forgotPasswordModal) {
      this.forgotPasswordModal = false;
    }

    this.loginModal = !this.loginModal;
  }

  @action setPartialRegistration = async (incomplete: boolean) => {
    this.incompleteSignup = incomplete;
  }

  @action createUserProfile = async (profileData: UserProfileData) => {
    const response: UserServiceResponse = await UserService.updateUser(profileData);
    if (response.success) {
      this._userProfile = response.data;
      return response;
    } else {
      captureException(response);
      return false;
    }
  }

  @action toggleForgotPasswordModal = () => {
    if (this.signUpModal) {
      this.signUpModal = false;
    }

    if (this.loginModal) {
      this.loginModal = false;
    }

    this.forgotPasswordModal = !this.forgotPasswordModal;
  }

  @action getBillingStatus = async () => {
    const response = await FuseBillService.getBillingStatus();
    if (response) {
      UserService.updatePaymentStatus(response);
    }
    if (response) {
      this._billingStatus = response;
      return this._billingStatus;
    }
    return null;
  }

  @action updateProfile = (profile: UserProfileData) => {
    this._userProfile = profile;
  }

  loadSignedInUser = async (userData: UserProfileData) => {
    if (this.loggedIn || this.incompleteSignup) {
      // don't overwrite properties with null values from craft
      Object.keys(userData).forEach((k: string) => {
        if (userData[k]) {
          this._userProfile[k] = userData[k];
        }
      });
    }
  }

  @computed get userProfile() {
    return this._userProfile;
  }

  @computed get billingStatus () {
    return this._billingStatus;
  }

  @computed get genderOptions() {
    return [
      { title: 'Male', value: 'male'},
      { title: 'Female', value: 'female'},
      { title: 'Rather not say', value: 'other'},
    ];
  }
}

export const getUserStore = getOrCreateStore('userStore', UserStore);
export default UserStore;
