import { BaseStore, getOrCreateStore } from 'next-mobx-wrapper';
import { action, computed, observable, ObservableMap, runInAction, flow } from 'mobx';
import { create, persist } from 'mobx-persist';
import { localStorage, localStorageSupported } from 'lib/Storage';
import { captureMessage } from 'isomorphic-sentry';
import { CommerceService, BlackhawkService } from 'lib/index';

import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';

import query from './query.graphql';

class Cart extends BaseStore {
  initialized = false;
  @observable hydrated = false;
  @observable cmsProducts: CMSProduct[] = [];
  @observable commerceCart: any = undefined;

  @persist schemaVersion = '';
  @persist('map') @observable _cartItems: ObservableMap<string, string> = observable.map();

  // Change this value to clear out all persisted data.
  currentSchemaVersion = '1';

  constructor(initialState: Cart) {
    super();
    if (!this.schemaVersion) {
      this.schemaVersion = this.currentSchemaVersion;
    }

    if (initialState) {
      this.cmsProducts = initialState.cmsProducts;
      this.commerceCart = initialState.commerceCart;
    }
  }

  init = flow<void, [ApolloClient<NormalizedCacheObject>]>(function *(
    this: Cart, apolloClient: ApolloClient<NormalizedCacheObject>
  ) {
    this.cmsProducts = [];
    const { data } = yield apolloClient.query({ query });
    const blackhawkProducts = yield data.products && BlackhawkService.getAvailableCards(data.products.map((p: CMSProduct) => p.blackhawkId));
    this.parseProducts(data.products, blackhawkProducts);
  })

  @action parseProducts = async (products: CMSProduct[], bhProducts: BlackhawkProduct[]) => {
    const blackhawkIds = bhProducts && bhProducts?.map((bhp: BlackhawkProduct) => bhp.defaultProductConfiguration);
    // only show products that can be read by the blackhawk api
    if (!!blackhawkIds.length) {
      const available = products.filter(p => blackhawkIds.includes(p.blackhawkId)).map(p => ({bh: bhProducts?.find(bhp => bhp.defaultProductConfiguration === p.blackhawkId), ...p }));
      this.cmsProducts = available;
    }
  }

  @action getProduct = (slug: string) => {
    return this.cmsProducts.find(cp => cp.slug === slug);
  }

  @action removeCartItem = (key: string) => {
    this._cartItems.delete(key);
  }

  @action clearCart = () => {
    this._cartItems.clear();
  }

  @action addToCart = (item: CMSVariant, parent: CMSProduct) => {
    const product = item;
    // eslint-disable-next-line
    // @ts-ignore ONLY used to store for observable map
    product.parent = JSON.stringify(parent);
    this._cartItems.set(new Date().toISOString(), JSON.stringify(product));
  }

  sendPaymentDetails = flow(function* (this: Cart, paymentId: string) {
    const response = yield CommerceService.sendPayment(paymentId);
    if (response.success) {
      return response;
    } else {
      captureMessage(response.error, response);
      return response.error;
    }
  })

  hydrate = flow<void, any>(function *(
    this: Cart
  ) {
    if (!this.initialized) {
      this.initialized = true;
      if (localStorageSupported) {
        // Clear persisted data if the schema version has changed
        const currentData = JSON.parse(localStorage.getItem('Cart'));
        if (currentData && currentData.schemaVersion !== this.currentSchemaVersion) {
          localStorage.clear();
        }

        const hydrate = create({ storage: localStorage });
        hydrate('Cart', this)
          .then(() => { runInAction(() => this.hydrated = true); });
      } else {
        this.hydrated = true;
      }
      const { cart } = yield CommerceService.getCart();
      this.commerceCart = cart;
    }
  })

  @action getCommerceCart = async () => {
    const { cart } = await CommerceService.getCart();
    this.commerceCart = cart;
  }

  @action addToCraftCommerce = async (userProfile: UserProfileData) => {
    if (this.commerceCart?.lineItems?.length) {
      await CommerceService.clearCart(this.commerceCart?.lineItems);
    }
    this.commerceCart = await CommerceService.addToCart(this.cartItems, userProfile);
  }

  @computed get cartItems() {
    const parsed = Array.from(this._cartItems.toJS()).map(i => { return {key: i[0], item: JSON.parse(i[1]) }; });
    return parsed.map(p => ({ ...p, item: { ...p.item, parent: JSON.parse(p.item.parent) }}));
  }

  @computed get cartNumber() {
    return this.cartItems.length;
  }
}

export const getCart = getOrCreateStore('cart', Cart);
export default Cart;
