import { storage, isSupported, MemoryStorage } from 'local-storage-fallback';

const localStorageSupported = isSupported('localStorage');
const sessionStorage = isSupported('sessionStorage') ? window.sessionStorage : new MemoryStorage();

export {
  storage as localStorage,
  localStorageSupported,
  sessionStorage
};
