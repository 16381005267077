import { BaseStore, getOrCreateStore } from 'next-mobx-wrapper';
import { action, computed, flow, observable, runInAction } from 'mobx';
import { create, persist } from 'mobx-persist';
import { localStorage, localStorageSupported } from 'lib/Storage';

import { AccommodationService } from 'lib/index';
import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import Offer from 'models/Offer';
import Property from 'models/Property';
import query from './query.graphql';
import { uniq } from 'lodash';

class AccommodationStore extends BaseStore {
  initialized = false;
  defaultMinPrice = 0;
  defaultMaxPrice = 300;
  @observable hydrated = false;

  @observable loading = false;
  @observable codes: string[] = []
  @observable properties: Property[] = []
  @observable property?: Property;
  @observable locations: string[] = [];
  @observable bookingUrl?: string;
  @observable activeBooking?: string;
  @observable offers: Offer[] = [];

  @persist schemaVersion = '';
  @persist @observable checkin?: string;
  @persist @observable checkout?: string;
  @persist @observable guests?: number;
  @persist @observable beds?: number ;
  @persist @observable baths?: number;
  @persist @observable minRate = this.defaultMinPrice;
  @persist @observable maxRate = this.defaultMaxPrice;
  @persist @observable location?: string;

  // Change this value to clear out all persisted data.
  currentSchemaVersion = '1';

  constructor(initialState: AccommodationStore) {
    super();
    if (!this.schemaVersion) {
      this.schemaVersion = this.currentSchemaVersion;
    }
    if (initialState) {
      // models are turned into objects without getters, so we don't create models until after initial load of store
      // istanbul ignore next
      for (const prop in initialState) {
        if (['properties', 'property', 'offers'].includes(prop)) {
          if (initialState.properties.length) {
            this.properties = initialState.properties.map((p: Accommodation.PropertyResult) => new Property(p));
          }
          if (initialState.property) {
            this.property = new Property(initialState.property);
          }
          if (initialState.offers) {
            this.offers = initialState.offers.map((o: OfferItem) => new Offer(o));
          }
        } else {
          this[prop] = initialState[prop];
        }
      }
    }
  }

  init = flow<void, [ApolloClient<NormalizedCacheObject>, string, { [key: string]: string }]>(function *(
    this: AccommodationStore, apolloClient: ApolloClient<NormalizedCacheObject>, pathname: string, search: { [key: string]: string }
  ) {
    this.loading = true;
    this.properties = []; // reset properties
    this.cmsLocations = [];
    // don't load all locations on homepage
    const [cms, locations, properties] = pathname === '/' ?
      yield Promise.all([
        yield apolloClient.query({ query }),
        yield AccommodationService.getLocations()
      ])
      : yield Promise.all([
        yield apolloClient.query({ query }),
        yield AccommodationService.getLocations(),
        yield AccommodationService.loadProperties(),
      ]);
    if (cms.data) {
      if (cms.data.offers) {
        this.offers = cms.data.offers;
      }
      if (cms.data.properties) {
        this.properties = cms.data.properties.map((p: Accommodation.PropertyResult ) => new Property(p));
      }
    }
    if (properties?.length) {
      this.properties = [...properties, ...this.properties].map((p) => new Property(p));
    }
    this.locations = locations;

    if (search?.location) {
      this.location = search.location;
    }
    if (search?.checkin && search?.checkout) {
      this.setBookingDates(search.checkin, search.checkout);
    }
    this.loading = false;
  })

  @action hydrate() {
    if (!this.initialized) {
      this.initialized = true;
      if (localStorageSupported) {
        // Clear persisted data if the schema version has changed
        const currentData = JSON.parse(localStorage.getItem('AccommodationStore'));
        if (currentData && currentData.schemaVersion !== this.currentSchemaVersion) {
          localStorage.clear();
        }

        const hydrate = create({ storage: localStorage });
        hydrate('AccommodationStore', this)
          .then(() => { runInAction(() => this.hydrated = true); });
      } else {
        this.hydrated = true;
      }
    }
  }

  @action resetFilters = () => {
    this.checkin = undefined;
    this.checkout = undefined;
    this.guests = undefined;
    this.beds = undefined;
    this.baths = undefined;
    this.minRate = 0;
    this.maxRate = 300;
    this.location = undefined;
  }

  @action setBookingDates = async (from: string, to: string, filter = true) => {
    if (filter) {
      this.properties.forEach((p: Property) => {
        p.checkAvailability(from, to);
      });
    }

    this.checkin = from;
    this.checkout = to;
  }

  @action setBookingUrl = (slug: string, link: string) => {
    this.activeBooking = slug;
    this.bookingUrl = link;
  }

  @action sameCityOffers = (city?: string) => {
    if (city && this.offers.length) {
      return this.offers.filter(o => o.location?.parts?.city === city);
    }
    return [];
  }

  @action loadCMSAccommodation = (accommodation: Accommodation.PropertyResult[] = []) => {
    const models = accommodation.map((property: Accommodation.PropertyResult) => new Property(property, true));
    const locations = this.locations?.length ? this.locations : [];
    this.properties = [...this.properties, ...models];
    this.locations = [
      ...locations,
      ...uniq(
        models
          .map((p: Property) =>
            p.listLocation
          ).filter(l => l) // removes undefined locations
      )
    ];
  }

  @action setFilter = (type: string, value: string) => {
    this[type] = parseInt(value, 10);
  }

  @action setPriceFilter = (min: number, max: number) => {
    this.minRate = min;
    this.maxRate = max;
  }

  @computed get filteredProperties() {
    if (!this.checkin && !this.checkout) {
      if (this.hasFilters) {
        // if there are filters
        return this.properties.filter((p: Property) => (
          !!p.meetsFilterConditions(this.filters)
        ));
      }
      return this.properties;
    }

    if (this.checkin && this.checkout) {
      if (this.hasFilters) {
        // if there are booking times and filters
        return this.properties.filter((p) => (
          p.meetsFilterConditions(this.filters)
        )).filter((p) => p.available);
      }
      return this.properties.filter((p: Property) => p.available);
    }
    return this.properties;
  }

  @computed get filters() {
    return {
      guests: this.guests,
      beds: this.beds,
      baths: this.baths,
      minRate: this.minRate !== this.defaultMinPrice ? this.minRate : undefined,
      maxRate: this.maxRate !== this.defaultMaxPrice ? this.maxRate : undefined,
      location: this.location,
      checkin: this.checkin,
      checkout: this.checkout,
    };
  }

  @computed get hasFilters() {
    // returns true if any of the filters have anything but undefined
    return !!Object.keys(this.filters)
      .map((f: string) => !!this.filters[f]) // returns true if filter
      .filter((b: boolean) => b) // removes all false
      .length; // filters exist if length
  }

  @computed get maxGuests() {
    let max = 6;
    if (this.properties.length) {
      max = Math.max(...this.properties.map((p: Property) =>p.maxAdults));
    }
    return max;
  }

  @computed get maxBeds() {
    let max = 6;
    // first pass of store properties are not models and i.e., dont have beds getter
    if (this.properties.length && this.properties[0] instanceof Property) {
      max = Math.max(...this.properties.map((p: Property) =>p.beds));
    }
    return max;
  }

  @computed get maxBaths() {
    let max = 4;
    if (this.properties.length) {
      max = Math.max(...this.properties.map((p: Property) =>p.bathrooms));
    }
    return max;
  }

  @computed get filterQueryString() {
    if (this.location || this.checkin) {
      return `?${this.location ? `location=${this.location}` : ''}${this.checkin ? `&checkin=${this.checkin}` : ''}${this.checkout ? `&checkout=${this.checkout}` : ''}`;
    }
    return '';
  }
}

export const getAccommodationStore = getOrCreateStore('accommodationStore', AccommodationStore);
export default AccommodationStore;
